import * as Sentry from "@sentry/vue";
import { fetchAuthSession } from "aws-amplify/auth";

export function setUser() {
	fetchAuthSession().then(session => {
		if (!session.tokens?.idToken) return;
		Sentry.setTags({
			accountId: session.tokens.idToken.payload[
				"custom:accountid"
			] as string,
		});
		Sentry.setUser({
			email: session.tokens.idToken.payload.email as string,
			username: session.tokens.idToken.payload[
				"cognito:username"
			] as string,
		});
	});
}
